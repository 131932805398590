export default defineNuxtRouteMiddleware((to) => {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        return navigateTo({
            name: 'auth__login',
            query: {
                redirect: urlSafeBtoa(to.fullPath),
                from: 'auth',
            },
        })
    }
})
